import React, { useState, useEffect } from "react";
import profilepic from "../../assets/images/profile-pic.png";
import logouticon from "../../assets/images/logout-icon.svg";
import softAcuityName from "../../assets/images/softAcuity_Name.png";
import softAcuityLogo from "../../assets/images/softAcuity_Logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { getToken, onlyOtherRoleAcess, tokenParse } from "../../Helper";
import { useTranslation } from 'react-i18next';
import "../../assets/css/sidebar.css"
const Sidebar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const logout = () => {
    if (getToken() !== undefined) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
  };

  const linkData = [
    {
      id: 'generateOTP',
      title: t('labels.DEVICE_ONBOARDING'),
      path: '/generateOTP',
    },
    {
      id: 'tenantManagement',
      title: t('labels.TENANT_MANAGEMENT'),
      path: '/tenantManagement',
    },
    {
      id: 'licenceInformation',
      title: t('labels.LICENCE_INFORMATION'),
      path: '/licenceInformation',
    },
    {
      id: 'deviceMovement',
      title: t('labels.DEVICE_MOVEMENT'),
      path: '/deviceMovement',
    },
    {
      id: 'tenantInformationManagement',
      title: t('labels.DEVICE_INFORMATION'),
      path: '/tenantInformation',
    },
    {
      id: 'caching',
      title: t('labels.CACHING'),
      path: '/caching',
    }
  ]


  return (
    <div className="side-bar">
      <div className="user-profile user-profile-left">
        <img src={softAcuityLogo} class="img-fluid" style={{ marginLeft: "20px" }} />
        <img src={softAcuityName} class="img-fluid" />
      </div>
      <div class="add-space-logout"></div> <button className="btn btn-link mt-4 p-0 logout-icon-left" onClick={logout}><img src={logouticon} /></button>
      <section className="inner-wrapper">
        <section class="list-wrapper list-wrapper-sidebar">
          <div class="list-content list-content-sidebar">
            <ul>
              {linkData.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={item.path}
                    style={{ textDecoration: "none" }}
                    id={item.id}
                    className={({ isActive }) => (isActive ? "link-active" : "link")}
                  >
                    <span>{item.title}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Sidebar;