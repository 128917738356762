import React, { useEffect, useState } from "react";
import "../assets/css/rdmportal.css";
import APIService from "../utils/APIService";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import { urlEndpoints } from "../utils/APIHelper";
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from "react-toastify";
import SimpleDropdown from "../components/common/SimpleDropdown";
import { getToken } from "../Helper";
import ReleaseVersionModal from "../components/ui/models/ReleaseVersionModal";

const TenantInformation = () => {
    const access = getToken();
    const [thingGroup, setThingGroup] = useState([]);
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [tenantName, setTenantName] = useState("");
    const [thingGroupData, setThingGroupData] = useState(null);
    const [loderText, setLoderText] = useState("Loading");
    const { t } = useTranslation();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedTenantItems, setSelectedTenantItems] = useState([]);
     const [templateData, setTemplateData] = useState([]);
    useEffect(() => {
        fetchTemplates();
        getThingGroups();
    }, []);
    useEffect(() => {
        if (thingGroupData?.things) {
            setSelectedTenantItems([]);
        }
    }, [thingGroupData]);
    const getThingGroups = () => {
        setLoaderVisibility(true)
        APIService(urlEndpoints.getThingGroups, {
            Authorization: `Bearer ${access}`,
        })
            .then((response) => {
                console.log(" response.data >> " + response.data);
                setThingGroup(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                setLoaderVisibility(false)
                if (error.response) {
                    toast.error(error.response.data.message, {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };

    const handleDropdownClick = (selectedOption) => {
        console.log("Selected Thing Group:", selectedOption);
        setTenantName(selectedOption); // Update state with selected value
    };

    const softRefresh = () => {
        if (!tenantName) return;
        APIService(
            urlEndpoints.getThingGroupInformation,
            { Authorization: `Bearer ${access}` },
            { params: tenantName }
        )
            .then((response) => {
                setThingGroupData(response.data);
            })
            .catch((error) => {
                toast.error(t('message.SERVER_ERROR'), {
                    autoClose: 2000,
                    theme: "dark",
                });
            });
    };
    useEffect(() => {
        if (thingGroupData) {
            const interval = setInterval(() => {
                softRefresh();
            }, 40000);
            return () => clearInterval(interval);
        }
    }, [thingGroupData, tenantName]);

    const getThingGroupInformation = () => {

        if (!tenantName) {
            toast.error('Please select the Tenant Name ', { autoClose: 2000, theme: 'dark' });
            return;
        }

        setLoaderVisibility(true);
        APIService(
            urlEndpoints.getThingGroupInformation,
            { Authorization: `Bearer ${access}` },
            { params: tenantName }
        )
            .then((response) => {
                console.log("Response is : " + JSON.stringify(response.data));
                setThingGroupData(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                setLoaderVisibility(false);
                if (error.response && error.response.status === 404) {
                    toast.error(t('message.TENANT_NOT_FOUND'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };

    const updateDeviceReportedShadow = (type, thingName, latestVersion, otherVer) => {
        console.log("type " + type);
        console.log("thigName " + thingName);
        console.log("latestVersion " + latestVersion);
        let dtoToSend;
        if (type === 'OS') {
            dtoToSend = {
                "thingName": thingName,
                "osVersion": latestVersion,
                "fpgaVersion": otherVer,
                "type": type
            };
        } else if (type === 'FPGA') {
            dtoToSend = {
                "thingName": thingName,
                "osVersion": otherVer,
                "fpgaVersion": latestVersion,
                "type": type
            };
        }

        console.log("dtoToSend:", JSON.stringify(dtoToSend));
        setLoaderVisibility(true);
        APIService(
            urlEndpoints.updateDeviceShadow,
            { Authorization: `Bearer ${access}` },
            JSON.stringify(dtoToSend)
        )
            .then((response) => {
                console.log("Response : " + response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                toast.error(t('message.SERVER_ERROR'), {
                    autoClose: 2000,
                    theme: "dark",
                });
            });
    };
    const handleTriggerDeploymentAction = () => {
        console.log("CHAND-selectedTenantItems="+selectedTenantItems.length)
        if (selectedTenantItems.length == 0) {
            toast.error("Please select at least one device from list", {
                autoClose: 2000,
                theme: "dark",
            });
            return;
        }
        setIsPopupOpen(true);
    };

    const handleReleaseVersionSaveAction = (template) => {
        setIsPopupOpen(false);
    };
    const handleReleaseVersionClose = () => {
        setIsPopupOpen(false);
    };

    const handleCheckboxChange = (thingName) => {
        setSelectedTenantItems((prevChecked) =>
            prevChecked.includes(thingName)
                ? prevChecked.filter((name) => name !== thingName) // Remove if already checked
                : [...prevChecked, thingName] // Add if not checked
        );
    };
    const fetchTemplates = () => {
        setLoaderVisibility(true);
        APIService(urlEndpoints.fetchDeploymentTemplates, {
            Authorization: `Bearer ${access}`,
        })
            .then((response) => {
                setTemplateData(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                setLoaderVisibility(false)
                if (error.response) {
                    toast.error(error.response.data.message, {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };
    return (
        <LayoutWrapper loderText={loderText} title={t('labels.DEVICE_INFORMATION')} visibility={loaderVisibility}>
            <section class="inner-wrapper table-top">
                <div class="w-100">
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.TENANT_NAME')}</label>
                        <SimpleDropdown
                            id="thingGroupDropdown"
                            name="thingGroup"
                            className="btn-group dpw-50"
                            options={thingGroup} // Dynamically fetched options
                            label={tenantName || "Select a Tenant"}
                            deopdownClick={handleDropdownClick}
                            scrollClassName="dropdown-scroll"
                        />
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn btn-secondary" type="submit" onClick={getThingGroupInformation}>{t('labels.SEARCH')}</button>
                    </div>
                    {thingGroupData && (
                        <div className="thin-border table-scroll">
                            <h3>{thingGroupData.thingGroupName}</h3>
                            <table className="table table-dark table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Thing Name</th>
                                        <th>Connected</th>
                                        <th>Deployment Status</th>
                                        <th>Version</th>
                                        <th colSpan={2}>OS Version</th>
                                        <th colSpan={2}>FPGA Version</th>
                                        {/* <th className="text-center" width="20%">Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {thingGroupData.things.map((board, idx) => (
                                        <tr key={idx}>
                                            <td>
                                                <input className="custom-checkbox"
                                                    type="checkbox"
                                                    checked={selectedTenantItems.includes(board.name)}
                                                    onChange={() => handleCheckboxChange(board.name)}
                                                />
                                                </td>
                                            <td>{board.name}</td>
                                            <td style={{ color: board.connected ? "green" : "red" }}>{board.connected ? "true" : "false"}</td>
                                            <td className={`status-cell ${board.latestDeploymentStatus}`} style={{ textAlign: 'left' }}>{board.latestDeploymentStatus}</td>

                                            <td>{board.version}</td>
                                            <td>{board.osVersion}</td>
                                            <td>
                                                {board.eligibleforOsUpdate ? (
                                                    <button
                                                        className="btn btn-secondary"
                                                        type="button"
                                                        onClick={() =>
                                                            !board.osNotifyToDevice &&
                                                            board.eligibleforOsUpdate &&
                                                            updateDeviceReportedShadow('OS', board.name, board.osLatestVersion, board.fpgaVersion)
                                                        }
                                                        disabled={board.osNotifyToDevice}
                                                    >
                                                        {board.osNotifyToDevice ? board.osStatus : t('labels.UPDATE')}
                                                    </button>
                                                ) : (
                                                    "-"
                                                )}
                                            </td>
                                            <td>{board.fpgaVersion}</td>
                                            <td>
                                                {board.eligibleforFPGAUpdate ? (
                                                    <button
                                                        className="btn btn-secondary"
                                                        type="button"
                                                        onClick={() =>
                                                            !board.fpgaNotifyToDevice &&
                                                            board.eligibleforFPGAUpdate &&
                                                            updateDeviceReportedShadow('FPGA', board.name, board.fpgaLatestVersion, board.osVersion)
                                                        }
                                                        disabled={board.fpgaNotifyToDevice}
                                                    >
                                                        {board.fpgaNotifyToDevice ? board.fpgaStatus : t('labels.UPDATE')}
                                                    </button>
                                                ) : (
                                                    "-"
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {thingGroupData?.things?.length > 0 && (
                        <div className="d-flex justify-content-end mt-4">
                             {/* <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={softRefresh}
                                style={{ marginRight: "10px" }}
                            >
                                {t("labels.REFRESH")}
                            </button> */}
                            <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={handleTriggerDeploymentAction}
                            >
                                {t("labels.DEPLOY")}
                            </button>
                        </div>
                    )}
                </div>

                <ReleaseVersionModal
                    isOpen={isPopupOpen}
                    selectedTenant={selectedTenantItems}
                    templates={templateData}
                    targetName={""}
                    tenantName={""}
                    onSave={handleReleaseVersionSaveAction}
                    onClose={handleReleaseVersionClose}
                />
            </section>
        </LayoutWrapper>
    );
};
export default TenantInformation;