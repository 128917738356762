import React, { useEffect, useState } from "react";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import "../assets/css/rdmportal.css";
import { useTranslation } from 'react-i18next';
import { getToken } from "../Helper";
import APIService from "../utils/APIService";
import { POST_PARAMS, urlEndpoints } from "../utils/APIHelper";
import { toast } from "react-toastify";
const Caching = () => {
    const { t } = useTranslation();
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loaderText, setLoaderText] = useState("Loading...");
    const access = getToken();

    const handleRefresh = (versionType) => {
        setLoaderVisibility(true);
        APIService(
            {
                endpoint: urlEndpoints.refreshVersionsCache.endpoint,
                methodType: POST_PARAMS,
            },
            { Authorization: `Bearer ${access}` },
            { params: versionType }
        )
            .then((response) => {
                setLoaderVisibility(false);
                toast.success(`${versionType} cache refreshed successfully!`, {
                    autoClose: 2000,
                    theme: "dark",
                });
            })
            .catch((error) => {
                setLoaderVisibility(false);
                if (error.response) {
                    toast.error(error.response.data.message, {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t("message.SERVER_ERROR"), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };

    return (
        <LayoutWrapper loderText={loaderText} title={t("labels.CACHING")} visibility={loaderVisibility}>
            <section className="inner-wrapper table-top">
                <div className="w-75" style={{width : "60%"}} >
                    <div className="text-center mt-4 refresh-container" >
                        <p style={{ width: '20%', textAlign: 'left', fontSize : '25px'}}>{t("labels.REFRESH_RELEASE_VERSION")}</p>
                        <button className="btn btn-secondary" onClick={() => handleRefresh("ReleaseVersions")}>
                            {t("labels.REFRESH")}
                        </button>
                    </div>
                    <div className="text-center mt-4 refresh-container" >
                        <p style={{ width: '20%', textAlign: 'left', fontSize : '25px'}}>{t("labels.REFRESH_OS_VERSION")}</p>
                        <button className="btn btn-secondary" onClick={() => handleRefresh("OS")}>
                            {t("labels.REFRESH")}
                        </button>
                    </div>
                    <div className="text-center mt-4 refresh-container" >
                        <p style={{ width: '20%', textAlign: 'left', fontSize : '25px'}}>{t("labels.REFRESH_FPGA_VERSION")}</p>
                        <button className="btn btn-secondary" onClick={() => handleRefresh("FPGA")}>
                            {t("labels.REFRESH")}
                        </button>
                    </div>
                    
                </div>
            </section>
        </LayoutWrapper>
    );
};
export default Caching;   