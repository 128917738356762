import React, { useState } from "react";
import APIService from "../../../utils/APIService";
import { urlEndpoints } from "../../../utils/APIHelper";
import { toast } from "react-toastify";
import { getToken } from "../../../Helper";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import closeIcons from "../../../assets/images/close-Icons.svg";

const ReleaseVersionModal = ({ isOpen, selectedTenant, templates, targetName, tenantName, onSave, onClose }) => {
    const access = getToken();
    const { t } = useTranslation();
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState("");

    const handleSave = () => {
        if (selectedTenant.length == 0) {
            if (!selectedTemplate) {
                toast.error("Please select a release version before saving.", {
                    autoClose: 2000,
                    theme: "dark",
                });
                return;
            }

            if (!tenantName || !targetName) {
                toast.error("Target and tenant names are required.", {
                    autoClose: 2000,
                    theme: "dark",
                });
                return;
            }

            setLoaderVisibility(true);

            const deviceMovementPayload = {
                thingName: targetName,
                newThingGroupName: tenantName,
                currentThingGroupName: "Inventory",
            };
        
            APIService(
                urlEndpoints.deviceMovement,
                { Authorization: `Bearer ${access}` },
                deviceMovementPayload
            )
                .then(() => {
                    const deployPayload = {
                        isThingGroup: false,
                        targetName,
                        releaseVersion: selectedTemplate,
                        isClearLocalData: false,
                    };

                    APIService(
                        urlEndpoints.deploy,
                        { Authorization: `Bearer ${access}` },
                        deployPayload
                    )
                        .then(() => {
                            toast.success(t("message.SELECTED_SUCCESSFULLY"), {
                                autoClose: 3000,
                                theme: "dark",
                            });
                            onSave(selectedTemplate);
                            setSelectedTemplate("");
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.message || t("message.ERROR_DURING_VERSION_SELECTION"),
                                { autoClose: 3000, theme: "dark" }
                            );
                        })
                        .finally(() => {
                            setLoaderVisibility(false);
                        });
                })
                .catch((error) => {
                    toast.error(
                        error.response?.data?.message || t("message.ERROR_DURING_DEVICE_MOVEMENT"),
                        { autoClose: 3000, theme: "dark" }
                    );
                    setLoaderVisibility(false);
                });
        } else {
            if (!selectedTemplate) {
                toast.error("Please select a release version before saving.", {
                    autoClose: 2000,
                    theme: "dark",
                });
                return;
            }
            const triggerDeploymentListPayload = selectedTenant.map((name) => ({
                isThingGroup: false,
                targetName: name,  
                releaseVersion: selectedTemplate,
                isClearLocalData: false
            }));
            console.log("triggerDeploymentListPayload="+triggerDeploymentListPayload)
            setLoaderVisibility(true);

            APIService(
                urlEndpoints.triggerDeployment,
                { Authorization: `Bearer ${access}` },
                triggerDeploymentListPayload
            )
                .then(() => {
                    toast.success(t("message.DEPLOYMENT_SUCCESSFULLY"), {
                        autoClose: 3000,
                        theme: "dark",
                    });
                    onSave(selectedTemplate);
                    setSelectedTemplate("");
                })
                .catch((error) => {
                    toast.error(
                        error.response?.data?.message || t("message.ERROR_DURING_VERSION_SELECTION"),
                        { autoClose: 3000, theme: "dark" }
                    );
                })
                .finally(() => {
                    setLoaderVisibility(false);
                });
        }
    };


    const handleClose = () => {
        setSelectedTemplate("");
        onClose();
    };

    if (!isOpen) return null;

    return (
        <>
            <Modal
                size="lg"
                show={isOpen}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <div className="modal-overlay">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={handleClose}>
                            <img className="img-fluid" src={closeIcons} alt="" />
                        </button>
                        <h3 className="modal-title">{t("labels.RELESED_VERSIONS")}</h3>
                        {/* <h4>{t("labels.SELECT_RELESED_VERSION")}</h4> */}
                    </div>
                    <div className="modal-body">
                        <div
                            className="version-list"
                            style={{
                                maxHeight: "300px",
                                overflowY: templates.length > 15 ? "auto" : "visible",
                                border: templates.length > 15 ? "1px solid #030303" : "none",
                                padding: "10px",
                            }}
                        >
                            {templates.length > 0 ? (
                                templates.map((template, index) => {
                                    const templateValue = template.replace('.json', '');
                                    return (
                                        <div
                                            key={index}
                                            className="radio-group"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                id={`template-${index}`}
                                                name="template"
                                                value={templateValue}
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    marginRight: "12px",
                                                }}
                                                onChange={(e) => setSelectedTemplate(e.target.value)}
                                            />
                                            <label htmlFor={`template-${index}`}>{templateValue}</label>
                                        </div>
                                    );
                                })
                            ) : (
                                    <p
                                        style={{ textAlign: "center" }}
                                        dangerouslySetInnerHTML={{ __html: t("labels.NO_RELESEVERSION_AVAILABLE") }}
                                    />
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={handleClose}
                        >
                            {t("labels.CANCEL")}
                        </button>
                        {templates.length > 0 && (
                            <button
                                className="btn btn-secondary"
                                onClick={handleSave}
                                disabled={loaderVisibility}
                            >
                                {loaderVisibility ? t("labels.Deploying...") : t("labels.SUBMIT")}
                            </button>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ReleaseVersionModal;
