import React, { useEffect, useState } from "react";
import APIService from "../utils/APIService";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import Input from "../components/common/Input";
import SimpleDropdown from "../components/common/SimpleDropdown";
import { urlEndpoints } from "../utils/APIHelper";
import { toast } from "react-toastify";
import { getToken } from "../Helper";
import { useTranslation } from 'react-i18next';
import ReleaseVersionModal from "../components/ui/models/ReleaseVersionModal";
import "../assets/css/rdmportal.css";
const DeviceMovement = () => {
    const access = getToken();
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loderText, setLoderText] = useState("Loading");
    const [tenantName, setTenantName] = useState("");
    const { t, i18n } = useTranslation();
    const [thingGroup, setThingGroup] = useState([]);
    const [thingNames, setThingNames] = useState([]);
    const [thingName, setThingName] = useState(""); // State for Thing Name
    const [templateData, setTemplateData] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    useEffect(() => {
        //fetchTemplates();
        getThingGroups();
        getThingsFromInventoryGroup();
    }, []);

    const getThingsFromInventoryGroup = () => {
        setLoaderVisibility(true)
        APIService(
            urlEndpoints.fetchThings,
            { Authorization: `Bearer ${access}` },
            { params: 'Inventory' }
        )
            .then((response) => {
                console.log(" response.data >> " + response.data);
                setThingNames(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                setLoaderVisibility(false)
                if (error.response) {
                    toast.error(error.response.data.message, {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };

    const getThingGroups = () => {
        setLoaderVisibility(true)
        APIService(urlEndpoints.getThingGroups, {
            Authorization: `Bearer ${access}`,
        })
            .then((response) => {
                setThingGroup(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                setLoaderVisibility(false)
                if (error.response) {
                    toast.error(error.response.data.message, {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };
    const downloadTemplates = () => {
        setLoaderVisibility(true);
        APIService(urlEndpoints.downloadDeploymentTemplates, {
            Authorization: `Bearer ${access}`,
        })
            .then((response) => {
                setTemplateData(response.data);
                setLoaderVisibility(false);
                toast.success(t('message.RELEASE_VERSIONS_DOWNLOADED_SUCESSFULLY'), {
                    autoClose: 2000,
                    theme: "dark",
                });
            })
            .catch((error) => {
                setLoaderVisibility(false)
                if (error.response) {
                    toast.error(error.response.data.message, {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };
    const handleFetchTemplatesClick = () => {
        downloadTemplates();
    };
    const handleDropdownClick = (selectedOption) => {
        console.log("Selected Thing Group:", selectedOption);
        setTenantName(selectedOption); // Update state with selected value
    };

    const handleThingSelectedDropdownClick = (selectedOption) => {
        console.log("Selected Thing :", selectedOption);
        setThingName(selectedOption); // Update state with selected value
    };

    const loadTemplates = () => {
        setLoaderVisibility(true);
        APIService(urlEndpoints.fetchDeploymentTemplates, {
            Authorization: `Bearer ${access}`,
        })
            .then((response) => {
                setTemplateData(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                setLoaderVisibility(false)
                if (error.response) {
                    toast.error(error.response.data.message, {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };

    const handleDeviceMovement = () => {        
        if (!thingName) {
            toast.error("Thing Name is required.", {
                autoClose: 2000,
                theme: "dark",
            });
            return;
        }

        if (!tenantName) {
            toast.error("Thing Group is required.", {
                autoClose: 2000,
                theme: "dark",
            });
            return;
        }
        setIsPopupOpen(true);
        loadTemplates();
    };
    const handleSaveTemplate = (template) => {
        setIsPopupOpen(false);
        setTenantName("");
        setThingName("");
        setSelectedTemplate("");
    };
    const handleClose = () => {
        setIsPopupOpen(false);
        setSelectedTemplate("");
    };

    return (<LayoutWrapper loderText={loderText} title={t('labels.DEVICE_MOVEMENT')} visibility={loaderVisibility}>
        <section class="inner-wrapper table-top">
            <div class="w-100">
                <form>
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.SOURCE_THING_GROUP')}</label>
                        <Input
                            parentDivClassName="etherner-input  dpw-50"
                            type="text"
                            id="srcThingGroup"
                            name="srcThingGroup"
                            value={"Inventory"}
                            autoComplete="off"
                            required={true}
                            className="sa-input"
                            maxLength={30}
                            readOnly={true}
                        />
                    </div>
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.THING_NAME')}</label>
                        <SimpleDropdown
                            id="thingName"
                            name="thingName"
                            className="btn-group dpw-50"
                            options={thingNames}
                            label={thingName || "Select a Thing"}
                            deopdownClick={handleThingSelectedDropdownClick}
                            scrollClassName="dropdown-scroll"
                        />
                    </div>

                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.TARGET_THING_GROUP')}</label>
                        <SimpleDropdown
                            id="trgThingGroup"
                            name="trgThingGroup"
                            className="btn-group dpw-50"
                            options={thingGroup.filter(group => group !== "Inventory")} // Dynamically fetched options
                            label={tenantName || "Select a Target Tenant"}
                            deopdownClick={handleDropdownClick}
                            scrollClassName="dropdown-scroll"
                        />
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                        <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={handleFetchTemplatesClick}
                            style={{ marginRight: "10px" }}
                        >
                            {t("labels.download_release_versions")}
                        </button>
                        <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={handleDeviceMovement}
                        >
                            {t("labels.SUBMIT")}
                        </button>
                    </div>
                </form>
            </div>
            <ReleaseVersionModal
                isOpen={isPopupOpen}
                selectedTenant={[]}
                templates={templateData}
                targetName={thingName}
                tenantName={tenantName}
                onSave={handleSaveTemplate}
                onClose={handleClose}
            />
        </section >
    </LayoutWrapper >);
}
export default DeviceMovement;   